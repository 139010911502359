<template>
    <div class="popDialog">
        <el-dialog
                v-if="addDialog"
                width="95%"
                top="30px"
                :visible.sync="addDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull"
                custom-class="custom-dialog">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>新增會員資產資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="4" class="selectTitleMid">
                                <span>會員資產資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span style="text-align: left;font-size: 16px;">公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="companyId" placeholder=""
                                           @change="handleCompanyChange">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span style="text-align: left;font-size: 16px;">分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="branchId" placeholder=""
                                           @change="handleBranchChange">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="customerId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in customerOptions"
                                            :key="item.customerId"
                                            :label="item.nickName"
                                            :value="item.customerId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產品牌:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="brand" placeholder="請輸入資產品牌"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>資產類型<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="assetsModelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in assetsOptions"
                                            :key="item.assetsModelTypeId"
                                            :label="item.assetsModel"
                                            :value="item.assetsModelTypeId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>資產顏色:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="assetsColor" placeholder="請輸入資產顏色"
                                ></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                    <span>資產識別碼<span
                                            class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="identifier" placeholder="請輸入資產識別碼"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span style="text-align: left;font-size: 16px;">價格區間:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="priceRange" placeholder="請輸入價格區間"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>國內/國外:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isImported"
                                                style="text-align: left;font-size: 16px;margin:15px 0px 15px 20px">
                                    <el-radio label="1">國外</el-radio>
                                    <el-radio label="0">國內</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>資產服務等級<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="serviceLevelTypeId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in serviceLevelOptions"
                                            :key="item.serviceLevelTypeId"
                                            :label="item.serviceLevel"
                                            :value="item.serviceLevelTypeId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格起算日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="startDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格終止日:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="endDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間"
                                        class="hide-icon"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>會員資格購買金額:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="purchaseAmount"
                                        placeholder="請輸入會員資格購買金額"

                                        type="number"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>折扣率%:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="discountPercentage"
                                        placeholder="請輸入折扣率"
                                        type="number"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="contactName" placeholder="請輸入聯絡人"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人性別:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="contactGender"
                                >
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>聯絡人電話:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="contactPhoneNumber" placeholder="請輸入聯絡人電話"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>備註:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="carRemark" placeholder="請輸入備註"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>照片1<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="photoBase64_1 != null" :src="'data:image/jpg;base64,' + photoBase64_1"
                                     alt="Image Preview 1"/>
                                <input type="file" ref="fileInput1" @change="handleFileUpload1"
                                       accept=".jpg"/>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span style="text-align: left;font-size: 16px;">照片2<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="photoBase64_2" :src="'data:image/jpg;base64,' + photoBase64_2"
                                     alt="Image Preview 1"/>
                                <input type="file" ref="fileInput2" @change="handleFileUpload2"
                                       accept=".jpg"/>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span style="text-align: left;font-size: 16px;">照片3<span>(僅支援jpg)</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <img v-if="photoBase64_3" :src="'data:image/jpg;base64,' + photoBase64_3"
                                     alt="Image Preview 1"/>
                                <input type="file" ref="fileInput3" @change="handleFileUpload3"
                                       accept=".jpg"/>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="addTemplate">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "addOrderDialog",
        components: {},
        props: {
            addDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                loginReturnDto: null,
                token: null,
                companyOptions: [],
                branchOptions: [],
                assetsOptions: [],
                serviceLevelOptions: [],
                customerOptions: [],
                assetsModelTypeId: null,
                serviceLevelTypeId: null,
                customerId: null,
                companyId: null,
                branchId: null,
                contactName: null,
                contactGender: null,
                contactPhoneNumber: null,
                brand: null,
                assetsModel: null,
                assetsColor: null,
                identifier: null,
                priceRange: null,
                isImported: null,
                serviceLevel: null,
                startDate: null,
                endDate: null,
                status: '正常',
                isUse: true,
                statusChangeDate: null,
                purchaseAmount: null,
                discountPercentage: null,
                carRemark: null,
                memberNumber: null,
                photoBase64_1: null,
                photoBase64_2: null,
                photoBase64_3: null,
                reload: false,
                companyType: null,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true
            }
        },
        mounted() {
        },
        methods: {
            init() {
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'));
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'));
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId;
                        this.companyType = this.loginReturnDto.companyType;
                        console.log("addCompanyDialog取得loginReturnDto.companyId=" + this.companyId);
                    }
                    //console.log("addCompanyDialog取得token=" + this.token);
                }
                if (this.isBranchid) {
                    this.branchId = this.loginReturnDto.branchId;
                }
                //查詢全部公司下拉選單資料
                this.searchCompanyList();
                //查詢全部分店下拉選單資料
                this.searchBranchList();
                //查詢公司會員下拉選單資料
                this.searchCustomerOptions();
                //依據條件查詢全部資產類型資料
                this.searchAssetsModelTypeList();
                //依據條件查詢全部資產服務等級資料
                this.searchServiceLevelTypeList();
            },
            closeDialog() {
                console.log("關閉Pop");
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            async addTemplate() {
                //新增資料
                let tmp = this;
                let url = "consumer/addCustomerAssets";
                try {
                    let parameter = {
                        "customerId": this.customerId,
                        "brand": this.brand,
                        "assetsModelTypeId": this.assetsModelTypeId,
                        "assetsColor": this.assetsColor,
                        "identifier": this.identifier,
                        "priceRange": this.priceRange,
                        "isImported": this.isImported == null ? null : this.isImported == "0" ? false : true,
                        "serviceLevelTypeId": this.serviceLevelTypeId,
                        "memberNumber": this.memberNumber,
                        "startDate": this.startDate,
                        "endDate": this.endDate,
                        "status": this.status,
                        "isUse": this.isUse,
                        "statusChangeDate": this.statusChangeDate,
                        "purchaseAmount": this.purchaseAmount == null || this.purchaseAmount == 0 ? null : this.purchaseAmount,
                        "discountPercentage": this.discountPercentage == null || this.discountPercentage == 0 ? null : this.discountPercentage,
                        "photo1": null,
                        "photo2": null,
                        "photo3": null,
                        "photoBase64_1": this.photoBase64_1,
                        "photoBase64_2": this.photoBase64_2,
                        "photoBase64_3": this.photoBase64_3,
                        "isActive": true,
                        "photoPath1": null,
                        "photoPath2": null,
                        "photoPath3": null,
                        "remark": this.carRemark,
                        "contactName": this.contactName,
                        "contactGender": this.contactGender == null ? null : parseInt(this.contactGender, 10),
                        "contactPhoneNumber": this.contactPhoneNumber,
                    };
                    if (!this.checkAddParameter(parameter)) {
                        console.log("新增驗證資料失敗!");
                        return;
                    }
                    tmp.reload = true;
                    tmp.loadingView();
                    let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                    if (responseData) {
                        tmp.$api.openMsg("新增資料完成!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog();
                            //  tmp.$emit('init');
                        }, 1000);
                    } else {
                        tmp.$api.openMsg("新增資料失敗!", "error");
                        console.log("新增資料失敗!");
                    }
                } catch (error) {
                    console.log("新增資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
                tmp.closeLoading();
            },
            checkAddParameter(parameter) {
                var ststus = true;
                if (parameter != null && parameter != '') {
                    if (parameter.startDate != null && parameter.startDate != '') {
                        if (parameter.endDate == null || parameter.endDate == '') {
                            console.log("會員資格起算日不為空時會員資格終止日不可為空!");
                            this.$api.openMsg("會員資格起算日不為空時會員資格終止日不可為空!", "error");
                            return false;
                        }
                        const start = new Date(parameter.startDate);
                        const end = new Date(parameter.endDate);
                        if (start > end) {
                            console.log("parameter.startDate=" + parameter.startDate);
                            console.log("parameter.endDate=" + parameter.endDate);
                            console.log("會員資格起算日及會員資格終止日設定時間異常!");
                            this.$api.openMsg("會員資格起算日及會員資格終止日設定時間異常!", "error");
                            return false;
                        }
                    }
                    if (parameter.endDate != null && parameter.endDate != '') {
                        if (parameter.startDate == null || parameter.startDate == '') {
                            console.log("會員資格終止日不為空時會員資格起算日不可為空!");
                            this.$api.openMsg("會員資格終止日不為空時會員資格起算日不可為空!", "error");
                            return false;
                        }
                    }
                    if (parameter.customerId == null || parameter.customerId == '') {
                        console.log("會員帳號不可為空!");
                        this.$api.openMsg("會員帳號不可為空!", "error");
                        return false;
                    }
                    if (parameter.assetsModelTypeId == null || !parameter.assetsModelTypeId) {
                        console.log("資產類型不可為空!");
                        this.$api.openMsg("資產類型不可為空!", "error");
                        return false;
                    }
                    if (parameter.identifier == null || !parameter.identifier) {
                        console.log("資產識別碼不可為空!");
                        this.$api.openMsg("資產識別碼不可為空!", "error");
                        return false;
                    }
                    if (parameter.serviceLevelTypeId == null || !parameter.serviceLevelTypeId) {
                        console.log("資產服務等級不可為空!");
                        this.$api.openMsg("資產服務等級不可為空!", "error");
                        return false;
                    }
                    if (parameter.status == null || parameter.status == '') {
                        console.log("會員資格狀態不可為空!");
                        this.$api.openMsg("會員資格狀態不可為空!", "error");
                        return false;
                    }
                    if (parameter.isActive == null || parameter.isActive == '') {
                        console.log("是否啟用不可為空!");
                        this.$api.openMsg("是否啟用不可為空!", "error");
                        return false;
                    }
                } else {
                    console.log("必填資料不可為空!");
                    this.$api.openMsg("必填資料不可為空!", "error");
                    return false;
                }
                return ststus;
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this;
                let url = "login/search/searchCompanyOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url);
                if (responseData != null) {
                    tmp.companyOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this;
                let url = "login/search/searchBranchOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "branchId": this.branchId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.branchOptions = responseData;
                    console.log("取得下拉選單資料 branchOptions=" + tmp.branchOptions);
                }
            },
            async searchCustomerOptions() {
                //查詢公司會員下拉選單資料
                let tmp = this;
                let url = "consumer/search/searchCustomerOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "branchId": this.branchId,
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.customerOptions = responseData;
                    console.log("取得下拉選單資料 customerOptions=" + tmp.customerOptions);
                }
            },
            async handleFileUpload1(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        this.$refs.fileInput1.value = '';
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    this.photoBase64_1 = await this.readFileAsBase64(file);
                    console.log("匯入客戶資料! photoBase64_1=" + this.photoBase64_1);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            async handleFileUpload2(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        this.$refs.fileInput2.value = '';
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    this.photoBase64_2 = await this.readFileAsBase64(file);
                    console.log("匯入客戶資料! photoBase64_2=" + this.photoBase64_2);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            }, async handleFileUpload3(event) {
                let tmp = this;
                console.log("開始載入檔案!");
                try {
                    const file = event.target.files[0]; // 獲取上傳的文件
                    // 檢查文件擴展名
                    if (file == null || !file.name.endsWith('.jpg')) {
                        tmp.$api.openMsg("僅支援上傳.jpg 文件!", "error");
                        return;
                    }
                    // 检查文件大小是否超过限制
                    if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                        tmp.$api.openMsg("文件大小不能超过 1MB!", "error");
                        this.$refs.fileInput3.value = '';
                        return;
                    }
                    console.log("Filename=" + file.name);
                    // 使用Promise處理文件讀取
                    this.photoBase64_3 = await this.readFileAsBase64(file);
                    console.log("匯入客戶資料! photoBase64_3=" + this.photoBase64_3);
                } catch (e) {
                    console.log("載入檔案發生異常! error=" + e);
                }
            },
            readFileAsBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result.split(',')[1]); // 去掉前缀 'data:image/jpeg;base64,'
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file); // 读取文件为 DataURL
                });
            },
            handleCompanyChange(value) {
                //選擇公司下拉觸發
                console.log("Selected company ID:", value);
                if (!this.isCompanyid) {
                    this.companyId = value;
                    this.branchId = null;
                    this.searchBranchList();
                }
                this.assetsModelTypeId = null;
                this.serviceLevelTypeId = null;
                this.assetsOptions = [];
                this.serviceLevelOptions = [];
                this.searchAssetsModelTypeList();
                this.searchServiceLevelTypeList();
            },
            handleBranchChange(value) {
                //選擇分店下拉觸發
                console.log("Selected Branch ID:", value);
                if (!this.isBranchid) {
                    this.branchId = value;
                    this.searchCustomerOptions();
                }
            },
            async searchAssetsModelTypeList() {
                //依據條件查詢全部資產類型資料
                let tmp = this;
                let url = "company/search/assetsModel";
                let parameter = {
                    "companyId": this.companyId,
                    "assetsModel": this.assetsModel,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.assetsOptions = responseData;
                    console.log("取得下拉選單資料 assetsOptions=" + tmp.assetsOptions);
                }
            },
            async searchServiceLevelTypeList() {
                //依據條件查詢全部資產服務等級資料
                let tmp = this;
                let url = "company/search/serviceLevel";
                let parameter = {
                    "companyId": this.companyId,
                    "serviceLevel": this.serviceLevel,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.serviceLevelOptions = responseData;
                    console.log("取得下拉選單資料 serviceLevelOptions=" + tmp.serviceLevelOptions);
                }
            },
        }
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
