export default [
    {
        prop: 'orderId',
        label: '工單號',
        width: '100', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'companyName',
        label: '公司名稱',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'branchName',
        label: '分店名稱',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'orderStatusString',
        label: '工單狀態',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'serviceFee',
        label: '服務費',
        width: '100',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'discountedServiceFee',
        label: '本次費用',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'discountPercentage',
        label: '折扣率',
        width: '100',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'totalWorkingHours',
        label: '預估總工時',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'startTime',
        label: '服務開始時間',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'endTime',
        label: '服務結束時間',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'createUser',
        label: '建單人員',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'createDate',
        label: '建單日期',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'action',
        label: '操作',
        width: '200',
        showOverflowTooltip: true,
        align: 'center',
        render: 'action',
        sortable: false
    }
]
